<template>
  <div class="settingNotification">
    <card-header-body ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <div class="content">
        <div
          class="content__item d-flex justify-content-between align-items-center"
          v-if="notificationSetting.line_id !== null"
        >
          <p class="content__item__label f-w3">
            スタンプといいねの通知
          </p>
          <label class="switch">
            <input
              @change="changeSetting('line_notification')"
              type="checkbox"
              v-model="notificationSetting.line_notification"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div
          class="content__item d-flex justify-content-between align-items-center"
        >
          <p class="content__item__label f-w3">新着メッセージ</p>
          <label class="switch">
            <input
              @change="changeSetting('message')"
              type="checkbox"
              v-model="notificationSetting.message"
              checked
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div
          class="content__item d-flex justify-content-between align-items-center"
        >
          <p class="content__item__label f-w3">
            オファーされた、快諾された通知
          </p>
          <label class="switch">
            <input
              @change="changeSetting('designated')"
              type="checkbox"
              v-model="notificationSetting.designated"
              checked
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div
          class="content__item d-flex justify-content-between align-items-center"
        >
          <p class="content__item__label f-w3">マッチング完了</p>
          <label class="switch">
            <input
              @change="changeSetting('match_confirmed')"
              type="checkbox"
              v-model="notificationSetting.match_confirmed"
              checked
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div
          class="content__item d-flex justify-content-between align-items-center"
        >
          <p class="content__item__label f-w3">自動キャンセルの通知</p>
          <label class="switch">
            <input
              @change="changeSetting('canceled_automatically')"
              type="checkbox"
              v-model="notificationSetting.canceled_automatically"
              checked
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div
          class="content__item d-flex justify-content-between align-items-center"
        >
          <p class="content__item__label f-w3">ギフトがきた通知</p>
          <label class="switch">
            <input
              @change="changeSetting('tipped')"
              type="checkbox"
              v-model="notificationSetting.tipped"
              checked
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div
          class="content__item d-flex justify-content-between align-items-center"
        >
          <p class="content__item__label f-w3">お知らせ通知</p>
          <label class="switch">
            <input
              @change="changeSetting('box_notification')"
              type="checkbox"
              v-model="notificationSetting.box_notification"
              checked
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div
          class="content__item d-flex justify-content-between align-items-center"
        >
          <p class="content__item__label f-w3">足あと通知</p>
          <label class="switch">
            <input
              @change="changeSetting('footprint')"
              type="checkbox"
              v-model="notificationSetting.footprint"
              checked
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div
          class="content__item d-flex justify-content-between align-items-center"
        >
          <p class="content__item__label f-w3">ランキング非表示</p>
          <label class="switch">
            <input
              @change="changeSetting('is_ranking_hidden')"
              type="checkbox"
              v-model="notificationSetting.is_ranking_hidden"
              checked
            />
            <span class="slider round"></span>
          </label>
        </div>
        <!--        <div-->
        <!--          class="content__item d-flex justify-content-between align-items-center"-->
        <!--        >-->
        <!--          <p class="content__item__label f-w3">年齢非表示</p>-->
        <!--          <label class="switch">-->
        <!--            <input-->
        <!--              @change="changeSetting('met')"-->
        <!--              type="checkbox"-->
        <!--              v-model="notificationSetting.met"-->
        <!--              checked-->
        <!--            />-->
        <!--            <span class="slider round"></span>-->
        <!--          </label>-->
        <!--        </div>-->
      </div>
    </div>
    <CardFooter ref="footer" />
  </div>
</template>
<script>
import CardHeader from "@/components/CardHeader";
import CardFooter from "@/components/CardFooter";
import { DEFAULT_FALSE, DEFAULT_TRUE } from "@/definition/constants";
export default {
  name: "settingNotification",
  components: {
    "card-header-body": CardHeader,
    CardFooter
  },
  metaInfo() {
    return {
      title: "通知、表示設定",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      headerTitle: {
        text: "通知、表示設定",
        isShowTitle: true,
        isShowLogo: false
      },
      notificationSetting: {}
    };
  },
  mounted() {
    window.onresize = this.reportWindowSize;
  },
  created() {
    this.getNotificationSetting();
    this.$nextTick(() => {
      this.reportWindowSize();
    });
  },
  methods: {
    reportWindowSize() {
      if (window.innerWidth < 768) {
        const vh = Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        );
        const headerHeight = this.$refs.header
          ? this.$refs.header.$el.clientHeight
          : 0;
        const footerHeight = this.$refs.footer
          ? this.$refs.footer.$el.clientHeight
          : 0;

        document.getElementById("content-scroll").style.height =
          vh - (headerHeight + footerHeight) + "px";
      }
    },
    getNotificationSetting() {
      this.$store
        .dispatch("notificationSetting/getNotificationSetting")
        .then(response => {
          this.notificationSetting = response.data;
        });
    },
    changeSetting(name) {
      this.notificationSetting[name] = this.notificationSetting[name]
        ? DEFAULT_TRUE
        : DEFAULT_FALSE;
      this.$store
        .dispatch(
          "notificationSetting/updateNotificationSetting",
          this.notificationSetting
        )
        .then(response => {
          this.notificationSetting = response.data;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/cast/settingNotification.scss";

.content-scroll {
  // overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   height: calc(100vh - 152px);
  // }
}
</style>
